var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svg svg-nav-item",
      attrs: {
        id: "svgGitlab",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 512 512",
        role: "img",
        width: "18",
        height: "18"
      }
    },
    [
      _c("title", [_vm._v("GitLab")]),
      _c("defs"),
      _c("path", {
        attrs: {
          fill: "#8892b0",
          d:
            "M105.2 24.9c-3.1-8.9-15.7-8.9-18.9 0L29.8 199.7h132c-.1 0-56.6-174.8-56.6-174.8zM.9 287.7c-2.6 8 .3 16.9 7.1 22l247.9 184-226.2-294zm160.8-88l94.3 294 94.3-294zm349.4 88l-28.8-88-226.3 294 247.9-184c6.9-5.1 9.7-14 7.2-22zM425.7 24.9c-3.1-8.9-15.7-8.9-18.9 0l-56.6 174.8h132z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }