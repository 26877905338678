var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svg img-fluid",
      attrs: {
        id: "logo",
        xmlns: "http://www.w3.org/2000/svg",
        baseProfile: "tiny",
        viewBox: "0 0 100 100",
        x: "0",
        y: "0",
        version: "1.1",
        width: "100",
        height: "100",
        "xml:space": "preserve"
      }
    },
    [
      _c("defs"),
      _c("path", {
        staticClass: "animate__path svg__stroke",
        attrs: {
          id: "outline",
          fill: "none",
          stroke: "#31bdb3",
          "stroke-width": "5",
          d: "M38 38h12.0000038l-.0000076 30 .0000076-30H62"
        }
      }),
      _c("path", {
        staticClass: "animate__path svg__stroke",
        attrs: {
          id: "initial",
          fill: "none",
          stroke: "#31bdb3",
          "stroke-width": "5",
          d:
            "M50.2025223 6.1173635L88 28.0001144v44l-38 22-38-22v-44L50.2025223 6.1173635l1.1628608.6732363"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }