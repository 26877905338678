var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section fp-section fp-section-four",
      attrs: { id: "work", name: "work" }
    },
    [
      _c(
        "h3",
        {
          staticClass:
            "mb-5 heading-count text-lighter animate__animated animate__fadeInUp",
          staticStyle: { "transition-delay": "100ms" }
        },
        [_vm._v(" Some Things I’ve Built ")]
      ),
      _vm._l(_vm.featuredPosts, function(post, index) {
        return [
          index % 2 === 0
            ? _c(
                "div",
                { key: post.uuid, staticClass: "row w-100 pt-3 pb-5" },
                [
                  _c("div", { staticClass: "col col-lg-26" }, [
                    post.webmurl !== "FALSE" && post.mp4url !== "FALSE"
                      ? _c("div", { staticClass: "project-img-wrapper" }, [
                          _c(
                            "a",
                            {
                              staticClass: "project-img-link",
                              attrs: { href: post.link, target: "_blank" },
                              on: { mouseover: _vm.play, mouseleave: _vm.pause }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "project-img-filter embed-container"
                                },
                                [
                                  _c(
                                    "video",
                                    {
                                      ref: "videoElem",
                                      refInFor: true,
                                      staticClass: "project-img project-video",
                                      attrs: {
                                        id: "inlineVideo",
                                        width: "450",
                                        height: "600",
                                        loop: "",
                                        playsinline: "",
                                        poster: post.img,
                                        muted: "",
                                        preload: ""
                                      },
                                      domProps: { muted: true }
                                    },
                                    [
                                      _c("source", {
                                        attrs: {
                                          src:
                                            "https://cdn.monkeyisland.com.au/static/hibot/hibot.webm",
                                          type: "video/webm"
                                        }
                                      }),
                                      _c("source", {
                                        attrs: {
                                          src:
                                            "https://cdn.monkeyisland.com.au/static/hibot/hibot.mp4",
                                          type: "video/mp4"
                                        }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          "Your browser does not support the video tag."
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _c("div", { staticClass: "project-img-wrapper" }, [
                          _c(
                            "a",
                            {
                              staticClass: "project-img-link",
                              attrs: { href: post.link, target: "_blank" }
                            },
                            [
                              _c("div", { staticClass: "project-img-filter" }, [
                                _c("img", {
                                  staticClass: "project-img",
                                  attrs: {
                                    sizes: "(max-width: 700px) 100vw, 700px",
                                    src: post.img,
                                    alt: post.title,
                                    width: post.imgH,
                                    height: post.imgH,
                                    loading: "lazy"
                                  }
                                })
                              ])
                            ]
                          )
                        ])
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col col-lg-22 d-flex flex-column align-items-end justify-content-center"
                    },
                    [
                      _c("h4", { staticClass: "text-teal h6 pt-3" }, [
                        _vm._v(" Featured Project ")
                      ]),
                      _c("h5", { staticClass: "h3 mb-4" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-lighter",
                            attrs: {
                              href: post.link,
                              target: "_blank",
                              rel: "nofollow noopener noreferrer",
                              "aria-label": "External Link"
                            }
                          },
                          [_vm._v(_vm._s(post.title))]
                        )
                      ]),
                      _c("div", {
                        staticClass: "callout-block",
                        domProps: { innerHTML: _vm._s(_vm.markdown(post.desc)) }
                      }),
                      _c("div", { staticClass: "d-block py-3 px-0" }, [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled list-horizontal" },
                          _vm._l(_vm.renderTags(post.tags), function(
                            tag,
                            tagIndex
                          ) {
                            return _c("li", { key: tagIndex }, [
                              _vm._v(" " + _vm._s(tag) + " ")
                            ])
                          }),
                          0
                        )
                      ]),
                      _c("div", { staticClass: "d-block pb-3 px-0" }, [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled list-horizontal" },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: post.git,
                                    target: "_blank",
                                    rel: "nofollow noopener noreferrer",
                                    "aria-label": "GitHub Link"
                                  }
                                },
                                [_c("iconGithub")],
                                1
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: post.git,
                                    target: "_blank",
                                    rel: "nofollow noopener noreferrer",
                                    "aria-label": "Gitlab Link"
                                  }
                                },
                                [_c("iconGitlab")],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            : _vm._e(),
          index % 2 === 1
            ? _c(
                "div",
                { key: post.uuid, staticClass: "row row-alt w-100 py-5" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col col-lg-22 d-flex flex-column align-items-start justify-content-center"
                    },
                    [
                      _c("h4", { staticClass: "text-teal h6 pt-3" }, [
                        _vm._v(" Featured Project ")
                      ]),
                      _c("h5", { staticClass: "h3 mb-4" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-lighter",
                            attrs: {
                              href: post.link,
                              target: "_blank",
                              rel: "nofollow noopener noreferrer",
                              "aria-label": "External Link"
                            }
                          },
                          [_vm._v(_vm._s(post.title))]
                        )
                      ]),
                      _c("div", {
                        staticClass: "callout-block",
                        domProps: { innerHTML: _vm._s(_vm.markdown(post.desc)) }
                      }),
                      _c("div", { staticClass: "d-block py-3 px-0" }, [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled list-horizontal" },
                          _vm._l(_vm.renderTags(post.tags), function(
                            tag,
                            tagIndex
                          ) {
                            return _c("li", { key: tagIndex }, [
                              _vm._v(" " + _vm._s(tag) + " ")
                            ])
                          }),
                          0
                        )
                      ]),
                      _c("div", { staticClass: "d-block pb-3 px-0" }, [
                        _c(
                          "ul",
                          { staticClass: "list-unstyled list-horizontal" },
                          [
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: post.git,
                                    target: "_blank",
                                    rel: "nofollow noopener noreferrer",
                                    "aria-label": "GitHub Link"
                                  }
                                },
                                [_c("iconGithub")],
                                1
                              )
                            ]),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: post.git,
                                    target: "_blank",
                                    rel: "nofollow noopener noreferrer",
                                    "aria-label": "Gitlab Link"
                                  }
                                },
                                [_c("iconGitlab")],
                                1
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  ),
                  _c("div", { staticClass: "col col-lg-26" }, [
                    post.webmurl !== "FALSE" && post.mp4url !== "FALSE"
                      ? _c("div", { staticClass: "project-img-wrapper" }, [
                          _c(
                            "a",
                            {
                              staticClass: "project-img-link",
                              attrs: { href: post.link, target: "_blank" },
                              on: { mouseover: _vm.play, mouseleave: _vm.pause }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "project-img-filter embed-container"
                                },
                                [
                                  _c(
                                    "video",
                                    {
                                      ref: "videoElem",
                                      refInFor: true,
                                      staticClass: "project-img project-video",
                                      attrs: {
                                        id: "inlineVideo",
                                        width: "450",
                                        height: "600",
                                        loop: "",
                                        playsinline: "",
                                        poster: post.img,
                                        muted: "",
                                        preload: ""
                                      },
                                      domProps: { muted: true }
                                    },
                                    [
                                      _c("source", {
                                        attrs: {
                                          src:
                                            "https://cdn.monkeyisland.com.au/static/hibot/hibot.webm",
                                          type: "video/webm"
                                        }
                                      }),
                                      _c("source", {
                                        attrs: {
                                          src:
                                            "https://cdn.monkeyisland.com.au/static/hibot/hibot.mp4",
                                          type: "video/mp4"
                                        }
                                      }),
                                      _c("p", [
                                        _vm._v(
                                          "Your browser does not support the video tag."
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      : _c("div", { staticClass: "project-img-wrapper" }, [
                          _c(
                            "a",
                            {
                              staticClass: "project-img-link",
                              attrs: { href: post.link, target: "_blank" }
                            },
                            [
                              _c("div", { staticClass: "project-img-filter" }, [
                                _c("img", {
                                  staticClass: "project-img",
                                  attrs: {
                                    sizes: "(max-width: 700px) 100vw, 700px",
                                    src: post.img,
                                    alt: post.title,
                                    width: post.imgH,
                                    height: post.imgH,
                                    loading: "lazy"
                                  }
                                })
                              ])
                            ]
                          )
                        ])
                  ])
                ]
              )
            : _vm._e()
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }