<template>
  <section
    id="blog"
    name="blog"
    class="section fp-section fp-section-five"
  >
    <h3
      class="heading-count text-lighter mb-0 d-block w-100 text-center animate__animated animate__fadeInUp"
      style="transition-delay: 100ms;"
    >
      Other Noteworthy Projects
    </h3>
    <h4 class="text-teal h6 text-center hover-under d-block w-100 pt-2 mb-5">
      <router-link
        to="/archive"
        class="link-primary text-mono"
        aria-label="View the archive"
      >
        view the archive
      </router-link>
    </h4>
    <div class="row w-100">
      <div
        id="card-group-deck"
        class="col-48 mb-5"
      >
        <b-card-group
          deck
          class="card-deck-masonry"
        >
          <template v-for="(post, index) in normalPosts">
            <blogGridCard
              v-if="index <= 5 || showAllProjects == true"
              :key="post.uuid"
              :msg="post.desc"
              :tags="post.tags"
              :title="post.title"
            />
          </template>
        </b-card-group>
      </div>
      <div class="col-48 d-flex flex-column justify-content-center align-items-center">
        <div
          class="animate__animated animate__fadeInUp"
          style="animation-delay: 5800ms;"
        >
          <div
            class="d-inline-block mb-3"
            @click="showAllProjects = true"
          >
            <button
              type="button"
              class="btn btn-lg btn-outline-primary text-mono text-primary-light"
              @click="showAllProjects = true"
            >
              View more
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import blogGridCard from '@/components/blog-grid-card';
import _ from 'lodash';
const md = require('markdown-it')();

export default {
  name: 'S5Archive',
  components: {
    blogGridCard
  },
  data() {
    return {
      showAllProjects: false
    };
  },
  computed: {
    posts() {
      return this.$root.posts;
    },
    loading() {
      return this.$root.loading;
    },
    normalPosts() {
      return _.filter(this.$root.posts, { status: '' });
    }
  },
  methods: {
    markdown: function(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return md.render(value);
    }
  }
};
</script>
