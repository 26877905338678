var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        scrolled: _vm.scrolled,
        scrolledPast: _vm.scrolledPast,
        scrolledPastOnce: _vm.scrolledPastOnce,
        animationComplete: _vm.animationComplete,
        loadComplete: _vm.loadComplete
      },
      attrs: { id: "app" }
    },
    [
      _vm.isHome
        ? _c(
            "div",
            {
              class: {
                wrapperLoader: true,
                animationComplete: _vm.animationComplete,
                loadComplete: _vm.loadComplete
              }
            },
            [_vm.isHome ? _c("svgLoader") : _vm._e()],
            1
          )
        : _vm._e(),
      _c("navMenu", {
        class: {
          scrolled: _vm.scrolled,
          scrolledPast: _vm.scrolledPast
        }
      }),
      _c(
        "transition",
        { attrs: { name: "fade", mode: "out-in" } },
        [_c("router-view")],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }