<template>
  <section
    id="about"
    name="about"
    class="section fp-section fp-section-two"
  >
    <h3
      class="heading-count text-lighter animate__animated animate__fadeInUp"
      style="transition-delay: 100ms;"
    >
      About Me
    </h3>
    <div class="row w-100">
      <div class="col-48 col-lg-24">
        <p
          class="text-light animate__animated animate__fadeInUp"
          style="transition-delay: 200ms;"
        >
          <span class="d-inline icon-wa-wrapper hover-under hover-under-span">
            G&rsquo;day, I&rsquo;m Tyson Broun, a
            <span class="text-lighter hover-under-span-target">full-stack software engineer</span>
            based in <br>
            <span class="d-inline icon-wa hover-under-span-target mr-2"><iconPerth /></span>
            <span class="d-inline icon-wa-text hover-under-span-target">Perth, Western Australia.</span>
          </span>
        </p>
        <p
          class="mb-4 text-light hover-under-span animate__animated animate__fadeInUp"
          style="transition-delay: 300ms;"
        >
          I enjoy creating things that live on the internet, whether that be
          <span class="text-lighter hover-under-span-target">websites</span>,
          <span class="text-lighter hover-under-span-target">applications</span>, or
          <span class="text-lighter hover-under-span-target">anything in between</span>.
          My goal is to always build products that provide pixel-perfect, performant experiences.
        </p>
        <ul
          class="section-list list-unstyled list-feature columns-two text-light mb-5 animate__animated animate__fadeInUp"
          style="transition-delay: 600ms;"
        >
          <li class="text-light">
            JavaScript &#40;ES6&#43;&#41;
          </li>
          <li class="text-light">
            Laravel
          </li>
          <li class="text-light">
            React/Vue.js
          </li>
          <li class="text-light">
            Meteor.js/Cordova
          </li>
          <li class="text-light">
            Node
          </li>
          <li class="text-light">
            WordPress
          </li>
        </ul>
      </div>
      <div class="col-48 col-lg-24 d-flex align-items-center justify-content-center">
        <div class="hover-img">
          <!-- <img class="img-fluid" src="@/assets/gs.jpg" width="300" height="300" alt="" />  -->
          <!-- <img class="img-fluid" src="../assets/gs.jpg" width="300" height="300" alt="" />  -->
          <!-- <img class="img-fluid" src="img/gs.jpg" width="300" height="300" alt="" />  -->
          <img
            class="img-fluid"
            src="https://s3.ap-southeast-2.amazonaws.com/ghostpirates/static/gtbw-bush.png"
            width="300"
            height="300"
            alt=""
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import iconPerth from '@/assets/icon-perth';

export default {
  name: 'S2About',
  components: {
    iconPerth
  },
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  }
};
</script>
