var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section fp-section fp-section-two",
      attrs: { id: "about", name: "about" }
    },
    [
      _c(
        "h3",
        {
          staticClass:
            "heading-count text-lighter animate__animated animate__fadeInUp",
          staticStyle: { "transition-delay": "100ms" }
        },
        [_vm._v(" About Me ")]
      ),
      _c("div", { staticClass: "row w-100" }, [
        _c("div", { staticClass: "col-48 col-lg-24" }, [
          _c(
            "p",
            {
              staticClass: "text-light animate__animated animate__fadeInUp",
              staticStyle: { "transition-delay": "200ms" }
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "d-inline icon-wa-wrapper hover-under hover-under-span"
                },
                [
                  _vm._v(" G’day, I’m Tyson Broun, a "),
                  _c(
                    "span",
                    { staticClass: "text-lighter hover-under-span-target" },
                    [_vm._v("full-stack software engineer")]
                  ),
                  _vm._v(" based in "),
                  _c("br"),
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-inline icon-wa hover-under-span-target mr-2"
                    },
                    [_c("iconPerth")],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-inline icon-wa-text hover-under-span-target"
                    },
                    [_vm._v("Perth, Western Australia.")]
                  )
                ]
              )
            ]
          ),
          _vm._m(0),
          _vm._m(1)
        ]),
        _vm._m(2)
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass:
          "mb-4 text-light hover-under-span animate__animated animate__fadeInUp",
        staticStyle: { "transition-delay": "300ms" }
      },
      [
        _vm._v(
          " I enjoy creating things that live on the internet, whether that be "
        ),
        _c("span", { staticClass: "text-lighter hover-under-span-target" }, [
          _vm._v("websites")
        ]),
        _vm._v(", "),
        _c("span", { staticClass: "text-lighter hover-under-span-target" }, [
          _vm._v("applications")
        ]),
        _vm._v(", or "),
        _c("span", { staticClass: "text-lighter hover-under-span-target" }, [
          _vm._v("anything in between")
        ]),
        _vm._v(
          ". My goal is to always build products that provide pixel-perfect, performant experiences. "
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      {
        staticClass:
          "section-list list-unstyled list-feature columns-two text-light mb-5 animate__animated animate__fadeInUp",
        staticStyle: { "transition-delay": "600ms" }
      },
      [
        _c("li", { staticClass: "text-light" }, [
          _vm._v(" JavaScript (ES6+) ")
        ]),
        _c("li", { staticClass: "text-light" }, [_vm._v(" Laravel ")]),
        _c("li", { staticClass: "text-light" }, [_vm._v(" React/Vue.js ")]),
        _c("li", { staticClass: "text-light" }, [
          _vm._v(" Meteor.js/Cordova ")
        ]),
        _c("li", { staticClass: "text-light" }, [_vm._v(" Node ")]),
        _c("li", { staticClass: "text-light" }, [_vm._v(" WordPress ")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-48 col-lg-24 d-flex align-items-center justify-content-center"
      },
      [
        _c("div", { staticClass: "hover-img" }, [
          _c("img", {
            staticClass: "img-fluid",
            attrs: {
              src:
                "https://s3.ap-southeast-2.amazonaws.com/ghostpirates/static/gtbw-bush.png",
              width: "300",
              height: "300",
              alt: ""
            }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }