<template>
  <div
    id="navLeft"
    orientation="left"
    class="nav nav-left"
  >
    <ul
      id="slideNav"
      class="slide-nav"
      @parentToChild="onChildClick"
    >
      <li
        class="nav-left-item"
        @click="scrollToSection('about')"
      >
        <span>1&period;</span>
      </li>
      <li
        class="nav-left-item"
        @click="scrollToSection('experience')"
      >
        <span>2&period;</span>
      </li>
      <li
        class="nav-left-item"
        @click="scrollToSection('work')"
      >
        <span>3&period;</span>
      </li>
      <li
        class="nav-left-item"
        @click="scrollToSection('blog')"
      >
        <span>4&period;</span>
      </li>
      <li
        class="nav-left-item"
        @click="scrollToSection('contact')"
      >
        <span>5&period;</span>
      </li>
    </ul>
    <ul
      id="slideNavBtns"
      class="list-unstyled"
    >
      <li class="nav-left-link">
        <a
          href="https://gitlab.com/GhostPirateBob"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="GitLab"
          class="animate__animated animate__fadeInUp"
          style="animation-delay: 5300ms;"
        >
          <iconGitlab />
        </a>
      </li>
      <li class="nav-left-link">
        <a
          href="https://github.com/GhostPirateBob"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="GitHub"
          class="animate__animated animate__fadeInUp"
          style="animation-delay: 5400ms;"
        >
          <iconGithub />
        </a>
      </li>
      <li class="nav-left-link">
        <a
          href="mailto:gpiratebob@gmail.com"
          rel="nofollow noopener noreferrer"
          aria-label="Email Me"
          class="animate__animated animate__fadeInUp"
          style="animation-delay: 5500ms;"
        >
          <iconMail />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import iconGithub from '@/assets/icon-github';
import iconGitlab from '@/assets/icon-gitlab';
import iconMail from '@/assets/icon-mail';

function isEmpty(value) {
  return value === null || typeof (value) === 'undefined' || value === '';
}

export default {
  name: 'NavLeft',
  components: {
    iconGithub,
    iconGitlab,
    iconMail
  },
  props: {
    stringProp: String()
  },
  methods: {
    // Triggered when `childToParent` event is emitted by the child.
    onChildClick(value) {
      this.fromParent = value;
      // console.log({ msg: 'herewego', val: value});
    },
    scrollToSection(value) {
      // console.log('clicked on: ' + value + ' section');
      if (!isEmpty(document.getElementById(value))) {
        document.getElementById(value).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
      }
    }
  }
};
</script>
