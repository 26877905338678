<template>
  <section
    id="experience"
    v-in-viewport="{ dataInViewport: &quot;true&quot; }"
    name="experience"
    class="section fp-section fp-section-three"
  >
    <h3
      class="heading-count text-lighter animate__animated animate__fadeInUp"
      style="transition-delay: 100ms;"
    >
      Where I've Worked
    </h3>
    <div class="row w-100">
      <div class="col-48">
        <span
          class="list-tracker"
          :class="selectedListItem"
        />
        <b-tabs
          id="section-tab-panel"
          vertical
          card
          no-fade
          active-nav-item-class="active"
          class="h-auto"
        >
          <b-tab
            title="Juicebox"
            active
            @click="setListTracker('item-0')"
          >
            <h5 class="hover-under">
              <span>Senior Front-End Engineer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Juicebox</a>
            </h5>
            <h6 class="text-light">
              2019 &#45; 2021
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Senior position overseeing WordPress &amp; Laravel PHP projects and JavaScript applications</li>
              <li>Front-end JavaScript development of both Web Applications and Native Mobile Apps</li>
              <li>Write modern, performant, and robust code for a diverse array of client and internal projects</li>
            </ul>
          </b-tab>
          <b-tab
            title="Loaded"
            @click="setListTracker('item-1')"
          >
            <h5 class="hover-under">
              <span>Senior Full-Stack Engineer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Loaded Communications</a>
            </h5>
            <h6 class="text-light">
              2017 &#45; 2019
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Worked with a variety of different languages, frameworks, and content management systems such as JavaScript, TypeScript, React, Vue, Laravel, Node.js, WordPress, Prismic, etc</li>
              <li>Communicate and collaborate with multi-disciplinary teams of engineers, designers, producers, clients, and stakeholders on a daily basis</li>
              <li>Write modern, performant, and robust code for a diverse array of client and internal projects</li>
            </ul>
          </b-tab>
          <b-tab
            title="Swan"
            @click="setListTracker('item-2')"
          >
            <h5 class="hover-under">
              <span>Full-Stack Web Engineer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Swan Taxis</a>
            </h5>
            <h6 class="text-light">
              2016 &#45; 2017
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Worked with a team of three engineers to build a marketing website, e-commerce platform and Google Maps API based backend for quoting and delivering transport and tourism services</li>
              <li>Helped solidify a brand direction for radio, print, web and social media campaigns</li>
              <li>Interfaced with clients on a weekly basis, providing technological expertise and knowledge</li>
            </ul>
          </b-tab>
          <b-tab
            title="CKY"
            @click="setListTracker('item-3')"
          >
            <h5 class="hover-under">
              <span>Full-Stack Web Engineer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >CKY Media</a>
            </h5>
            <h6 class="text-light">
              2014 &#45; 2016
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Helped develop and ship a 3D VR (Augmented Reality) app and platform for interactive site tours on location before the property was constructed (bought by BGC Construction Group)</li>
              <li>Architected and implemented the front-end of several small and medium businesses in Perth and collaborated closely with the design team to support the backend infrastructure</li>
              <li>Contributed to several backend libraries on GitHub for automated content design and delivery (MJML/Gulp/AWS)</li>
            </ul>
          </b-tab>
          <b-tab
            title="Atamo"
            @click="setListTracker('item-4')"
          >
            <h5 class="hover-under">
              <span>Embedded Software Developer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Atamo Engineering</a>
            </h5>
            <h6 class="text-light">
              2012 &#45; 2014
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Developed individual components for larger industrial process control systems and worked with electronic engineers to build automated solutions for large WA enterprises</li>
              <li>Helped the engineering team to adopt new development tools in the industry based around Arduino and Raspberry Pi rapid-prototyping trends</li>
            </ul>
          </b-tab>
          <b-tab
            title="Artech"
            @click="setListTracker('item-5')"
          >
            <h5 class="hover-under">
              <span>Full-Stack Web Developer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Artech Web</a>
            </h5>
            <h6 class="text-light">
              2010 &#45; 2012
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Engineered and maintained major websites and backend systems using ES6, Handlebars, Backbone, Python Django, and CSS/Sass</li>
              <li>Proposed and implemented scalable solutions to issues identified with cloud services and applications responsible for communicating with numerous enterprise API’s</li>
              <li>Collaborates with UX designers and other developers to ensure thoughtful and coherent user experiences across web and related iOS/Android mobile apps</li>
            </ul>
          </b-tab>
          <b-tab
            title="Haycom"
            @click="setListTracker('item-6')"
          >
            <h5 class="hover-under">
              <span>Full-Stack Web Developer</span>
              <span class="text-email">&#x40;</span>
              <a
                href="#"
                rel="nofollow noopener noreferrer"
                class="text-teal"
              >Haycom Technlogies</a>
            </h5>
            <h6 class="text-light">
              2005 &#45; 2009
            </h6>
            <ul class="section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100">
              <li>Developed and maintained Microsoft servers and systems for in-house and client websites primarily using HTML, CSS, Sass, JavaScript, and jQuery</li>
              <li>Planned, built and deployed over a hundred Microsoft Server 2003 backend and server platforms for SME's in Perth</li>
            </ul>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'S3Experience',
  components: {
  },
  data() {
    return {
      selectedListItem: 'item-0'
    };
  },
  methods: {
    setListTracker(item) {
      if (item !== null || item !== undefined) {
        this.selectedListItem = item;
        // console.log({ itemVal: item, sli: this.selectedListItem });
      }
    }
  }
};
</script>

<style lang="scss">

</style>
