// Require the polyfills before requiring any other modules.
import 'intersection-observer';
import 'mutationobserver-shim';
import Vue from 'vue';

import './plugins/vuera';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import { cacheAdapterEnhancer } from 'axios-extensions';
import './plugins/bootstrap-vue';
import './plugins/service-worker';
import inViewportDirective from 'vue-in-viewport-directive';
import VueObserveVisibility from 'vue-observe-visibility';
import Papa from 'papaparse';

// maybe let's not go straight for jquery... (ง •̀_•́)ง
// import $ from 'jquery'

// we will load bootstrap.native instead!..  (っ◕‿◕)っ
import BootstrapNative from 'bootstrap.native/dist/bootstrap-native-v4';
import App from './app.vue';
import router from './router';
// const dev = process.env.NODE_ENV !== 'production';

Vue.use(VueObserveVisibility);
Vue.prototype.$BSN = BootstrapNative;

Vue.config.productionTip = false;
inViewportDirective.defaults.margin = '-15% 0%';
Vue.directive('in-viewport', inViewportDirective);

const POSTS_URL = 'https://docs.google.com/spreadsheets/d/1dc6vjqj3I-3XNxY1MiSNeTZLEJKP-M9POURNxsdoTTE/gviz/tq?tqx=out:csv&sheet=data';
const GITLAB_PROJECTS_01 = require('./data/projects-01.json');
const GITLAB_PROJECTS_02 = require('./data/projects-02.json');
const GITLAB_PROJECTS_ARR = [...GITLAB_PROJECTS_01, ...GITLAB_PROJECTS_02];

console.log(GITLAB_PROJECTS_01, GITLAB_PROJECTS_02, GITLAB_PROJECTS_ARR);

const http = axios.create({
  baseURL: '/',
  headers: { 'Cache-Control': 'no-cache' },
  // cache will be enabled by default
  adapter: cacheAdapterEnhancer(axios.defaults.adapter)
});

const app = new Vue({
  data() {
    return {
      loading: true,
      posts: [],
      projects: []
    };
  },
  created() {
    this.projects = GITLAB_PROJECTS_ARR;
    http.get(POSTS_URL); // make real http request
    http.get(POSTS_URL); // use the response from the cache of previous request, without real http request made
    http.get(POSTS_URL, { cache: true }) // disable cache manually and the the real http request invoked
      .then((response) => {
        const parsedData = Papa.parse(response.data, {
          header: true
        });
        this.posts = parsedData.data;
        this.loading = false;
        console.log(parsedData.data);
      })
      .catch(error => {
        console.log(error);
      });
  },
  router,
  render: h => h(App)
});

app.$mount('#app');

// bootstrap.native dropdowns
const myDropdowns = document.querySelectorAll('.bsn-dropdown');
const dropdownArray = [];
Array.from(myDropdowns).forEach(link => {
  dropdownArray.push(Vue.prototype.$BSN.Dropdown(link, true));
});
