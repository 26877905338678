var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass: "section fp-section fp-section-one",
        attrs: { id: "landing", name: "landing" }
      },
      [
        _c(
          "p",
          {
            staticClass:
              "text-primary-light mt-5 text-mono animate__animated animate__fadeInUp",
            staticStyle: { "animation-delay": "5200ms" }
          },
          [_vm._v(" Hi, my name is ")]
        ),
        _c(
          "h1",
          {
            staticClass: "text-lighter animate__animated animate__fadeInUp",
            staticStyle: { "animation-delay": "5300ms" }
          },
          [_vm._v(" Tyson Broun. ")]
        ),
        _c(
          "h2",
          {
            staticClass: "text-light mb-5 animate__animated animate__fadeInUp",
            staticStyle: { "animation-delay": "5400ms" }
          },
          [_vm._v(" I build things for the web. ")]
        ),
        _c(
          "div",
          {
            staticClass: "animate__animated mb-5 animate__fadeInUp",
            staticStyle: { "animation-delay": "5500ms" }
          },
          [
            _c("p", { staticClass: "text-light mw-500" }, [
              _vm._v(
                " I’m a software engineer based in Perth, WA specializing in building (and occasionally designing) exceptional websites, applications, and everything in between. "
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "animate__animated animate__fadeInUp",
            staticStyle: { "animation-delay": "5600ms" }
          },
          [
            _c(
              "a",
              {
                staticClass: "d-inline-block mb-3",
                attrs: {
                  href: "mailto:ghostpiratebob@gmail.com",
                  target: "_blank"
                }
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-lg btn-outline-primary text-mono text-primary-light",
                    attrs: { type: "button" }
                  },
                  [_vm._v("Get In Touch")]
                )
              ]
            )
          ]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }