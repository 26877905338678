var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svg",
      attrs: {
        id: "svgPerth",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0",
        y: "0",
        version: "1.1",
        viewBox: "0 0 512 512",
        "xml:space": "preserve",
        role: "img",
        width: "24",
        height: "24"
      }
    },
    [
      _c("title", [_vm._v("Perth, WA")]),
      _c("defs"),
      _c("path", {
        staticClass: "wa",
        attrs: {
          fill: "rgba(136, 146, 176, .25)",
          d:
            "M180.4 93.4l-8.5-7.7c-1.5-1.4-3.9-1.4-5.4 0l-39.8 37.4c-4.9 4.6-9.2 9.8-12.9 15.4l-3 4.6c-9.9 15-23.7 27.2-39.9 35.1l-34 16.7c-7.3 3.6-13.1 9.6-16.3 17-4 9-4 19.4.1 28.4l37.2 81.4c3.2 6.9 3.3 14.9.3 21.9l-.7 1.8c-1.9 4.6-.9 9.9 2.6 13.4 4.1 4.1 10.6 4.8 15.4 1.5 1.8-1.2 8.6-4.1 14.9-6 5.2-6 13.3-12.9 15.6-14.1 3-1.5 6.2-2.4 9.6-2.7l28.8-2.7c.8-.1 1.5-.4 2.1-.8l25.4-19.5c2.2-1.7 4.9-2.9 7.6-3.5l21.3-4.5V96.8c-2.3 1-4.8 1.5-7.4 1.5-5 0-9.5-1.8-13-4.9z",
          stroke: "rgba(136, 146, 176, 1)",
          "stroke-width": "16"
        }
      }),
      _c("path", {
        attrs: {
          fill: "rgba(136, 146, 176, 1)",
          d:
            "M505.1 232.1l-20.8-31.5c-2.3-3.5-6.9-4.4-10.4-2.1-3.5 2.3-4.4 6.9-2.1 10.4l20.8 31.5c4 6.1 5.4 13.6 3.7 20.7l-14.6 61.1c-.1.5-.3.9-.6 1.3l-25.7 37.3c-12.6 18.3-32.4 30-54.5 32.3-18.3 1.9-36.5-1.7-52.7-10.3l-9.1-4.8c-1.7-.9-3-2.5-3.5-4.5l-6.5-27.2c-1.6-6.9-6.6-12.6-13.1-15.2-6.6-2.6-14.1-1.9-20 2-1.3.8-3 .9-4.3 0L238.2 300c-4.2-2.6-9.1-3.4-14-2.4l-26 5.5v15.3l29.1-6.2c1-.2 2.1 0 2.9.5l53.5 33.1c6.2 3.8 14.2 3.8 20.4-.2 1.9-1.2 4.2-1.5 6.3-.6 2.1.8 3.6 2.6 4.1 4.8L321 377c1.5 6.1 5.5 11.3 11 14.2l9.1 4.8c15.6 8.2 32.8 12.5 50.2 12.5 3.7 0 7.3-.2 11-.6 26.4-2.7 50.2-16.8 65.3-38.7l25.7-37.3c1.3-1.9 2.3-4.1 2.8-6.4l14.6-61.1c2.8-10.9.7-22.8-5.6-32.3zM433.7 427.4c-11.4-5.2-33.3-2.4-45.4-.3-6 1-19.9 3.5-25.1 14.9-3.7 8.1-1.3 18.3 5.7 23.8 3.6 2.8 7.6 4 11.1 5 2.3.7 4.5 1.3 5.8 2.3 1.5 1.1 2.9 3.1 4.5 5.3 2.9 4.1 6.5 9.3 13 11.3 1.8.6 3.6.8 5.4.8 3 0 5.9-.7 8.5-1.3 7-1.8 14-4.1 20.8-6.7 2.3-.9 6.5-2.5 9-6.6 4.9-7.9 3.9-19.9 1.4-28.5-1.8-5.8-6-16-14.7-20zm.7 40.2c-.3.2-.9.5-1.9.8-6.3 2.4-12.7 4.5-19.2 6.2-1.5.4-4.3 1.1-5.7.7-1.6-.5-3.3-2.9-5.1-5.5-2.1-3-4.5-6.4-8.1-9-3.4-2.4-7.1-3.5-10.3-4.4-2.4-.7-4.7-1.4-6-2.4-1.6-1.2-2.2-3.9-1.4-5.7 1.7-3.8 8.5-5.4 14.1-6.4 9.2-1.6 17.9-2.5 24.7-2.5 5.4 0 9.6.5 11.9 1.6 1.9.8 4.7 4.5 6.5 10.6 2.1 6.8 1.9 13.4.5 16z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }