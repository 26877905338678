var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "svg",
      attrs: {
        id: "svgArrowUp",
        xmlns: "http://www.w3.org/2000/svg",
        x: "0",
        y: "0",
        version: "1.1",
        viewBox: "0 0 18 18",
        "xml:space": "preserve",
        role: "img",
        width: "18",
        height: "18"
      }
    },
    [
      _c("title", [_vm._v("Back To Top")]),
      _c("defs"),
      _c("path", {
        attrs: { fill: "#8892b0", d: "M2.6 17.5L11 9H8.9l-7.4 7.4" }
      }),
      _c("path", { attrs: { fill: "#8892b0", d: "M2.6.5L11 9H8.9L1.5 1.6" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }