var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nav nav-right",
      attrs: { id: "navRight", orientation: "right" }
    },
    [
      _c(
        "div",
        {
          staticClass: "nav-right-btt",
          on: {
            click: function($event) {
              return _vm.scrollElementIntoView("main")
            }
          }
        },
        [
          _c(
            "div",
            { class: { running: _vm.running }, attrs: { id: "backToTop" } },
            [
              _c(
                "span",
                { staticClass: "arrow first next" },
                [_c("iconArrowUp")],
                1
              ),
              _c(
                "span",
                { staticClass: "arrow second next" },
                [_c("iconArrowUp")],
                1
              )
            ]
          )
        ]
      ),
      _vm._m(0)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nav-right-link" }, [
      _c(
        "a",
        {
          staticClass: "animate__animated animate__fadeInUp",
          staticStyle: { "animation-delay": "5400ms" },
          attrs: { href: "mailto:gpiratebob@gmail.com", target: "_blank" }
        },
        [_vm._v(" gpiratebob@gmail.com ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }