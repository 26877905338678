<template>
  <div
    id="main"
    class="main"
  >
    <navLeft />
    <!-- <navLeft :stringProp="visibleSection" /> -->
    <navRight />
    <!-- <s1Entry v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's1Entry')" />
    <s2About v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's2About')" />
    <s3Experience v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's3Experience')" />
    <s4Folio v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's4Folio')" />
    <s5Archive v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's5Archive')" />
    <s6Footer v-observe-visibility="(isVisible, entry) => visibilityChanged(isVisible, entry, 's6Footer')" /> -->
    <s1Entry />
    <s2About />
    <s3Experience />
    <s4Folio />
    <s5Archive />
    <s6Footer />
    <footerLinks />
  </div>
</template>

<script>
// @ is an alias to /src
import s1Entry from '@/components/s1-entry';
import s2About from '@/components/s2-about';
import s3Experience from '@/components/s3-experience';
import s4Folio from '@/components/s4-folio';
import s5Archive from '@/components/s5-archive';
import s6Footer from '@/components/s6-footer';
import footerLinks from '@/components/footer-links';
import navLeft from '@/components/nav-left';
import navRight from '@/components/nav-right';

export default {
  name: 'Home',
  components: {
    s1Entry,
    s2About,
    s3Experience,
    s4Folio,
    s5Archive,
    s6Footer,
    footerLinks,
    navLeft,
    navRight
  },
  data() {
    return {
      selectedListItem: String,
      isVisible: Boolean,
      visibleSection: String
    };
  },
  methods: {
    setListTracker(item) {
      if (item !== null || item !== undefined) {
        this.selectedListItem = item;
        console.log({ itemVal: item, sli: this.selectedListItem });
      }
    },
    visibilityChanged(isVisible, entry, customArgument) {
      // this.isVisible = isVisible
      if (isVisible === true) {
        this.visibleSection = customArgument;
        // console.log(isVisible)
        // console.log(customArgument)
      }
    }
  }
};
</script>
