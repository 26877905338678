var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { toggled: _vm.toggled },
      attrs: {
        id: "wrapper-navbar",
        itemscope: "",
        itemtype: "http://schema.org/WebSite"
      }
    },
    [
      _c(
        "a",
        {
          staticClass: "skip-link sr-only sr-only-focusable",
          attrs: { href: "#content" }
        },
        [_vm._v("Skip to content")]
      ),
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg", type: "dark" } },
        [
          _c(
            "b-navbar-brand",
            {
              on: {
                click: function($event) {
                  return _vm.scrollElementIntoView("app")
                }
              }
            },
            [
              _c("router-link", { attrs: { to: "/" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/logo-stroke.svg"),
                    width: "54",
                    height: "54",
                    alt: ""
                  }
                })
              ])
            ],
            1
          ),
          _c("b-navbar-toggle", {
            attrs: { target: "nav-collapse" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var expanded = ref.expanded
                  return [
                    expanded
                      ? _c("span", { staticClass: "toggler-icon-close" })
                      : _c("span", { staticClass: "toggler-icon" })
                  ]
                }
              }
            ])
          }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scrollElementIntoView("about")
                        }
                      }
                    },
                    [
                      _c("b-button", { attrs: { variant: "link-light" } }, [
                        _vm._v(" About ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scrollElementIntoView("experience")
                        }
                      }
                    },
                    [
                      _c("b-button", { attrs: { variant: "link-light" } }, [
                        _vm._v(" Experience ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scrollElementIntoView("work")
                        }
                      }
                    },
                    [
                      _c("b-button", { attrs: { variant: "link-light" } }, [
                        _vm._v(" Work ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scrollElementIntoView("blog")
                        }
                      }
                    },
                    [
                      _c("b-button", { attrs: { variant: "link-light" } }, [
                        _vm._v(" Projects ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      on: {
                        click: function($event) {
                          return _vm.scrollElementIntoView("contact")
                        }
                      }
                    },
                    [
                      _c("b-button", { attrs: { variant: "link-light" } }, [
                        _vm._v(" Contact ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item",
                    {
                      attrs: {
                        href:
                          "https://s3.ap-southeast-2.amazonaws.com/ghostpirates/static/TYSON-BROUN-RESUME.pdf",
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "b-button",
                        { attrs: { variant: "outline-primary" } },
                        [_vm._v(" Resume ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }