var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: {
        title: _vm.title,
        "bg-variant": "dark",
        "text-variant": "white"
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "wrapper-card-header d-flex align-items-start justify-content-between"
                },
                [
                  _c("div", { staticClass: "d-block" }, [_c("iconFolder")], 1),
                  _c(
                    "div",
                    { staticClass: "d-block" },
                    [
                      _c("iconGitlab", { staticClass: "mr-3" }),
                      _c("iconExpand")
                    ],
                    1
                  )
                ]
              )
            ]
          },
          proxy: true
        },
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "ul",
                { staticClass: "list-unstyled list-horizontal" },
                _vm._l(_vm.renderTags(_vm.tags), function(tag, index) {
                  return _c("li", { key: index }, [
                    _vm._v(" " + _vm._s(tag) + " ")
                  ])
                }),
                0
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", {
        domProps: { innerHTML: _vm._s(_vm.markdown(_vm.msg)) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }