
<template>
  <b-card
    :title="title"
    bg-variant="dark"
    text-variant="white"
  >
    <template #header>
      <div class="wrapper-card-header d-flex align-items-start justify-content-between">
        <div class="d-block">
          <iconFolder />
        </div>
        <div class="d-block">
          <iconGitlab class="mr-3" />
          <iconExpand />
        </div>
      </div>
    </template>
    <b-card-text v-html="markdown(msg)" />
    <template #footer>
      <ul class="list-unstyled list-horizontal">
        <li
          v-for="(tag,index) in renderTags(tags)"
          :key="index"
        >
          {{ tag }}
        </li>
      </ul>
    </template>
  </b-card>
</template>
<script>
import iconFolder from '@/assets/svg-folder';
import iconExpand from '@/assets/svg-expand';
import iconGitlab from '@/assets/svg-gitlab';
const md = require('markdown-it')();

export default {
  name: 'BlogGridCard',
  components: {
    iconFolder,
    iconExpand,
    iconGitlab
  },
  props: {
    msg: String(),
    tags: String(),
    title: String()
  },
  methods: {
    markdown: function(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return md.render(value);
    },
    renderTags: function(value) {
      if (value !== null && value !== undefined && value.length > 0) {
        return value.split('|');
      }
      return '';
    }
  }
};
</script>

<style scoped lang="scss">

</style>
