var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      {
        staticClass: "section fp-section fp-section-footer position-relative",
        attrs: { id: "contact", name: "contact" }
      },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-48 d-flex flex-column align-items-center justify-content-center"
            },
            [
              _c(
                "h4",
                {
                  staticClass:
                    "heading-count text-teal h6 mb-3 animate__animated animate__fadeInUp",
                  staticStyle: { "transition-delay": "6800ms" }
                },
                [_vm._v(" What’s Next? ")]
              ),
              _c(
                "h3",
                {
                  staticClass:
                    "h3 mb-4 text-lighter animate__animated animate__fadeInUp",
                  staticStyle: { "transition-delay": "6900ms" }
                },
                [_vm._v(" Get In Touch ")]
              ),
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col-48" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-light text-center text-small mw-600 animate__animated animate__fadeInUp",
                      staticStyle: { "transition-delay": "7000ms" }
                    },
                    [
                      _vm._v(
                        " Currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you! "
                      )
                    ]
                  )
                ])
              ])
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "col-48 d-flex flex-column justify-content-center align-items-center"
            },
            [
              _c(
                "div",
                {
                  staticClass: "animate__animated animate__fadeInUp",
                  staticStyle: { "animation-delay": "7100ms" }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "d-inline-block mb-3",
                      attrs: {
                        href: "mailto:ghostpiratebob@gmail.com",
                        target: "_blank"
                      }
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-lg btn-outline-primary text-mono text-primary-light",
                          attrs: { type: "button" }
                        },
                        [_vm._v("Say Hello")]
                      )
                    ]
                  )
                ]
              )
            ]
          )
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }