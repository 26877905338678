<template>
  <div
    id="navRight"
    orientation="right"
    class="nav nav-right"
  >
    <div
      class="nav-right-btt"
      @click="scrollElementIntoView('main')"
    >
      <div
        id="backToTop"
        :class="{ running: running }"
      >
        <span class="arrow first next">
          <iconArrowUp />
        </span>
        <span class="arrow second next">
          <iconArrowUp />
        </span>
      </div>
    </div>
    <div class="nav-right-link">
      <a
        href="mailto:gpiratebob@gmail.com"
        target="_blank"
        class="animate__animated animate__fadeInUp"
        style="animation-delay: 5400ms;"
      > gpiratebob@gmail.com </a>
    </div>
  </div>
</template>

<script>
import iconArrowUp from '@/assets/icon-arrow-up';

export default {
  name: 'NavRight',
  components: {
    iconArrowUp
  },
  data: function() {
    return {
      running: false
    };
  },
  methods: {
    scrollElementIntoView: function(elem, event) {
      if (event) {
        event.preventDefault();
      }
      if (this.$route.name !== 'Home') {
        // console.log('pushing to Home');
        this.$router.push({ path: '/', name: 'Home' });
        setTimeout(() => {
          if (elem && document.getElementById(elem)) {
            // console.log({ 'scrollIntoViewElem': elem, 'scrollEvent': event });
            document.getElementById(elem).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start'
            });
          }
        }, 2000);
      }
      if (this.$route.name === 'Home') {
        if (elem && document.getElementById(elem)) {
          // console.log({ 'scrollIntoViewElem': elem, 'scrollEvent': event });
          document.getElementById(elem).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          });
        }
      }
    }
  }
};
</script>
