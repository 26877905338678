<template>
  <div class="row">
    <div
      id="footer-links-wrapper"
      class="col-48 d-flex flex-column align-items-center justify-content-center"
    >
      <a
        href="https://gitlab.com/GhostPirateBob"
        target="_blank"
        rel="nofollow noopener noreferrer"
        class="footer-link text-center text-mono"
      >
        <span>Designed &amp; Built by Tyson Broun</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterLinks',
  methods: {
  }
};
</script>

<style scoped lang="scss">

</style>
