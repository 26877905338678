<template>
  <section
    id="contact"
    name="contact"
    class="section fp-section fp-section-footer position-relative"
  >
    <div class="row">
      <div class="col-48 d-flex flex-column align-items-center justify-content-center">
        <h4
          class="heading-count text-teal h6 mb-3 animate__animated animate__fadeInUp"
          style="transition-delay: 6800ms;"
        >
          What&rsquo;s Next&#63;
        </h4>
        <h3
          class="h3 mb-4 text-lighter animate__animated animate__fadeInUp"
          style="transition-delay: 6900ms;"
        >
          Get In Touch
        </h3>
        <div class="row w-100">
          <div class="col-48">
            <p
              class="text-light text-center text-small mw-600 animate__animated animate__fadeInUp"
              style="transition-delay: 7000ms;"
            >
              Currently looking for any new opportunities, my inbox is always open. Whether you have a question or just want to say hi, I&rsquo;ll try my best to get back to you&#33;
            </p>
          </div>
        </div>
      </div>
      <div class="col-48 d-flex flex-column justify-content-center align-items-center">
        <div
          class="animate__animated animate__fadeInUp"
          style="animation-delay: 7100ms;"
        >
          <a
            class="d-inline-block mb-3"
            href="mailto:ghostpiratebob@gmail.com"
            target="_blank"
          >
            <button
              type="button"
              class="btn btn-lg btn-outline-primary text-mono text-primary-light"
            >Say Hello</button>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'S6Footer',
  components: {
  }
};
</script>
