<template>
  <div
    id="app"
    :class="{
      scrolled: scrolled,
      scrolledPast: scrolledPast,
      scrolledPastOnce: scrolledPastOnce,
      animationComplete: animationComplete,
      loadComplete: loadComplete,
    }"
  >
    <div
      v-if="isHome"
      :class="{
        wrapperLoader: true,
        animationComplete: animationComplete,
        loadComplete: loadComplete,
      }"
    >
      <svgLoader v-if="isHome" />
    </div>
    <navMenu
      :class="{
        scrolled: scrolled,
        scrolledPast: scrolledPast,
      }"
    />
    <!-- <transition
      name="fade"
      mode="out-in"
    >
      <div class="container form-wrapper d-block">
        <div class="px-3 persistance-test form-group d-flex flex-row">
          <p class="mr-3">
            Persistant field 1 (string) <input
              v-model="name"
              class="form-control"
              placeholder="enter a string ... "
              type="text"
            >
          </p>
          <p>
            Persistant field 2 (int) <input
              v-model="age"
              class="form-control"
              placeholder="enter an int ... "
              type="number"
            >
          </p>
        </div>
        <div class="px-3 form-group">
          <p>
            <button
              class="btn btn-outline-primary"
              @click="persist"
            >
              Save to localStorage
            </button>
          </p>
        </div>
        <div class="row">
          <div class="col px-3">
            <h3 class="text-monospace d-block w-100">
              Debug Stuff
            </h3>
            <pre class="projects-pre">{{ renderProjects() }} </pre>
          </div>
        </div>
      </div>
    </transition> -->
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
import navMenu from '@/components/nav-menu.vue';
import svgLoader from '@/components/svg-loader.vue';
import _ from 'lodash';

let scrolledPastCheck = false;

function checkScroll() {
  if (window.scrollY > 0) {
    return true;
  }
  return false;
}

function checkScrolledPast() {
  if (window.scrollY >= 367) {
    return true;
  }
  return false;
}

function checkScrolledPastOnce() {
  if (window.scrollY >= 367 && scrolledPastCheck === false) {
    scrolledPastCheck = true;
    return true;
  }
  if (scrolledPastCheck === true) {
    return true;
  }
  return false;
}

export default {
  name: 'App',
  components: {
    navMenu,
    svgLoader
  },
  data: function() {
    return {
      scrolled: false,
      scrolledPast: false,
      scrolledPastOnce: false,
      animationComplete: false,
      loadComplete: false,
      name: '',
      age: 0
    };
  },
  computed: {
    isHome() {
      return this.$route.name === 'Home';
    }
  },
  created: function() {
    window.addEventListener('scroll', this.scrollCheck);
    this.loadComplete = true;
    setTimeout(() => {
      this.animationComplete = true;
      document.body.id = 'animationFinished';
    }, 4250);
  },
  unmounted: function() {
    window.removeEventListener('scroll', this.scrollCheck);
  },
  mounted() {
    if (localStorage.name) {
      this.name = localStorage.name;
    }
    if (localStorage.age) {
      this.age = localStorage.age;
    }
  },
  methods: {
    scrollCheck: _.throttle(function() {
      this.scrolled = checkScroll();
      this.scrolledPast = checkScrolledPast();
      this.scrolledPastOnce = checkScrolledPastOnce();
    }, 125),
    renderMarkdown: () => {},
    persist() {
      localStorage.name = this.name;
      localStorage.age = this.age;
      console.log('Saving to localStorage...');
    },
    renderProjects() {
      return this.$root.projects;
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/index.scss";
.project-img-link {
  border-radius: 0 !important;
}
.persistance-test {
  margin-top: 160px;
  display: block;
  p {
    color: #ccc;
    margin-bottom: 0;
    font-size: 16px;
  }
  .form-control {
      background: $darkest;
      border-color: rgb(40 159 152);
      color: #aaa;
  }
}
.projects-pre {
  max-height: 300px;
  display: block;
  overflow-y: auto;
  white-space: pre-wrap;
  word-break: break-all;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  outline: 1px solid #71849a;
  color: #fff;
}

</style>
