<template>
  <section
    id="landing"
    name="landing"
    class="section fp-section fp-section-one"
  >
    <p
      class="text-primary-light mt-5 text-mono animate__animated animate__fadeInUp"
      style="animation-delay: 5200ms;"
    >
      Hi, my name is
    </p>
    <h1
      class="text-lighter animate__animated animate__fadeInUp"
      style="animation-delay: 5300ms;"
    >
      Tyson Broun.
    </h1>
    <h2
      class="text-light mb-5 animate__animated animate__fadeInUp"
      style="animation-delay: 5400ms;"
    >
      I build things for the web.
    </h2>
    <div
      class="animate__animated mb-5 animate__fadeInUp"
      style="animation-delay: 5500ms;"
    >
      <p class="text-light mw-500">
        I&rsquo;m a software engineer based in Perth, WA specializing in building &#40;and occasionally designing&#41; exceptional websites, applications, and everything in between.
      </p>
    </div>
    <div
      class="animate__animated animate__fadeInUp"
      style="animation-delay: 5600ms;"
    >
      <a
        class="d-inline-block mb-3"
        href="mailto:ghostpiratebob@gmail.com"
        target="_blank"
      >
        <button
          type="button"
          class="btn btn-lg btn-outline-primary text-mono text-primary-light"
        >Get In Touch</button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'S1Entry',
  components: {
  }
};
</script>
