var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-48 d-flex flex-column align-items-center justify-content-center",
          attrs: { id: "footer-links-wrapper" }
        },
        [
          _c(
            "a",
            {
              staticClass: "footer-link text-center text-mono",
              attrs: {
                href: "https://gitlab.com/GhostPirateBob",
                target: "_blank",
                rel: "nofollow noopener noreferrer"
              }
            },
            [_c("span", [_vm._v("Designed & Built by Tyson Broun")])]
          )
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }