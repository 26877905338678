var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main", attrs: { id: "main" } },
    [
      _c("navLeft"),
      _c("navRight"),
      _c("s1Entry"),
      _c("s2About"),
      _c("s3Experience"),
      _c("s4Folio"),
      _c("s5Archive"),
      _c("s6Footer"),
      _c("footerLinks")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }