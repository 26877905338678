var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nav nav-left",
      attrs: { id: "navLeft", orientation: "left" }
    },
    [
      _c(
        "ul",
        {
          staticClass: "slide-nav",
          attrs: { id: "slideNav" },
          on: { parentToChild: _vm.onChildClick }
        },
        [
          _c(
            "li",
            {
              staticClass: "nav-left-item",
              on: {
                click: function($event) {
                  return _vm.scrollToSection("about")
                }
              }
            },
            [_c("span", [_vm._v("1.")])]
          ),
          _c(
            "li",
            {
              staticClass: "nav-left-item",
              on: {
                click: function($event) {
                  return _vm.scrollToSection("experience")
                }
              }
            },
            [_c("span", [_vm._v("2.")])]
          ),
          _c(
            "li",
            {
              staticClass: "nav-left-item",
              on: {
                click: function($event) {
                  return _vm.scrollToSection("work")
                }
              }
            },
            [_c("span", [_vm._v("3.")])]
          ),
          _c(
            "li",
            {
              staticClass: "nav-left-item",
              on: {
                click: function($event) {
                  return _vm.scrollToSection("blog")
                }
              }
            },
            [_c("span", [_vm._v("4.")])]
          ),
          _c(
            "li",
            {
              staticClass: "nav-left-item",
              on: {
                click: function($event) {
                  return _vm.scrollToSection("contact")
                }
              }
            },
            [_c("span", [_vm._v("5.")])]
          )
        ]
      ),
      _c(
        "ul",
        { staticClass: "list-unstyled", attrs: { id: "slideNavBtns" } },
        [
          _c("li", { staticClass: "nav-left-link" }, [
            _c(
              "a",
              {
                staticClass: "animate__animated animate__fadeInUp",
                staticStyle: { "animation-delay": "5300ms" },
                attrs: {
                  href: "https://gitlab.com/GhostPirateBob",
                  target: "_blank",
                  rel: "nofollow noopener noreferrer",
                  "aria-label": "GitLab"
                }
              },
              [_c("iconGitlab")],
              1
            )
          ]),
          _c("li", { staticClass: "nav-left-link" }, [
            _c(
              "a",
              {
                staticClass: "animate__animated animate__fadeInUp",
                staticStyle: { "animation-delay": "5400ms" },
                attrs: {
                  href: "https://github.com/GhostPirateBob",
                  target: "_blank",
                  rel: "nofollow noopener noreferrer",
                  "aria-label": "GitHub"
                }
              },
              [_c("iconGithub")],
              1
            )
          ]),
          _c("li", { staticClass: "nav-left-link" }, [
            _c(
              "a",
              {
                staticClass: "animate__animated animate__fadeInUp",
                staticStyle: { "animation-delay": "5500ms" },
                attrs: {
                  href: "mailto:gpiratebob@gmail.com",
                  rel: "nofollow noopener noreferrer",
                  "aria-label": "Email Me"
                }
              },
              [_c("iconMail")],
              1
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }