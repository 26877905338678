var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "section fp-section fp-section-five",
      attrs: { id: "blog", name: "blog" }
    },
    [
      _c(
        "h3",
        {
          staticClass:
            "heading-count text-lighter mb-0 d-block w-100 text-center animate__animated animate__fadeInUp",
          staticStyle: { "transition-delay": "100ms" }
        },
        [_vm._v(" Other Noteworthy Projects ")]
      ),
      _c(
        "h4",
        {
          staticClass:
            "text-teal h6 text-center hover-under d-block w-100 pt-2 mb-5"
        },
        [
          _c(
            "router-link",
            {
              staticClass: "link-primary text-mono",
              attrs: { to: "/archive", "aria-label": "View the archive" }
            },
            [_vm._v(" view the archive ")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "row w-100" }, [
        _c(
          "div",
          { staticClass: "col-48 mb-5", attrs: { id: "card-group-deck" } },
          [
            _c(
              "b-card-group",
              { staticClass: "card-deck-masonry", attrs: { deck: "" } },
              [
                _vm._l(_vm.normalPosts, function(post, index) {
                  return [
                    index <= 5 || _vm.showAllProjects == true
                      ? _c("blogGridCard", {
                          key: post.uuid,
                          attrs: {
                            msg: post.desc,
                            tags: post.tags,
                            title: post.title
                          }
                        })
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "col-48 d-flex flex-column justify-content-center align-items-center"
          },
          [
            _c(
              "div",
              {
                staticClass: "animate__animated animate__fadeInUp",
                staticStyle: { "animation-delay": "5800ms" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-inline-block mb-3",
                    on: {
                      click: function($event) {
                        _vm.showAllProjects = true
                      }
                    }
                  },
                  [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-lg btn-outline-primary text-mono text-primary-light",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.showAllProjects = true
                          }
                        }
                      },
                      [_vm._v(" View more ")]
                    )
                  ]
                )
              ]
            )
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }