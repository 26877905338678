var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      directives: [
        {
          name: "in-viewport",
          rawName: "v-in-viewport",
          value: { dataInViewport: "true" },
          expression: '{ dataInViewport: "true" }'
        }
      ],
      staticClass: "section fp-section fp-section-three",
      attrs: { id: "experience", name: "experience" }
    },
    [
      _c(
        "h3",
        {
          staticClass:
            "heading-count text-lighter animate__animated animate__fadeInUp",
          staticStyle: { "transition-delay": "100ms" }
        },
        [_vm._v(" Where I've Worked ")]
      ),
      _c("div", { staticClass: "row w-100" }, [
        _c(
          "div",
          { staticClass: "col-48" },
          [
            _c("span", {
              staticClass: "list-tracker",
              class: _vm.selectedListItem
            }),
            _c(
              "b-tabs",
              {
                staticClass: "h-auto",
                attrs: {
                  id: "section-tab-panel",
                  vertical: "",
                  card: "",
                  "no-fade": "",
                  "active-nav-item-class": "active"
                }
              },
              [
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Juicebox", active: "" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-0")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Senior Front-End Engineer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Juicebox")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2019 - 2021 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Senior position overseeing WordPress & Laravel PHP projects and JavaScript applications"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Front-end JavaScript development of both Web Applications and Native Mobile Apps"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Write modern, performant, and robust code for a diverse array of client and internal projects"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Loaded" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-1")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Senior Full-Stack Engineer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Loaded Communications")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2017 - 2019 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Worked with a variety of different languages, frameworks, and content management systems such as JavaScript, TypeScript, React, Vue, Laravel, Node.js, WordPress, Prismic, etc"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Communicate and collaborate with multi-disciplinary teams of engineers, designers, producers, clients, and stakeholders on a daily basis"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Write modern, performant, and robust code for a diverse array of client and internal projects"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Swan" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-2")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Full-Stack Web Engineer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Swan Taxis")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2016 - 2017 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Worked with a team of three engineers to build a marketing website, e-commerce platform and Google Maps API based backend for quoting and delivering transport and tourism services"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Helped solidify a brand direction for radio, print, web and social media campaigns"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Interfaced with clients on a weekly basis, providing technological expertise and knowledge"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "CKY" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-3")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Full-Stack Web Engineer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("CKY Media")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2014 - 2016 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Helped develop and ship a 3D VR (Augmented Reality) app and platform for interactive site tours on location before the property was constructed (bought by BGC Construction Group)"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Architected and implemented the front-end of several small and medium businesses in Perth and collaborated closely with the design team to support the backend infrastructure"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Contributed to several backend libraries on GitHub for automated content design and delivery (MJML/Gulp/AWS)"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Atamo" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-4")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Embedded Software Developer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Atamo Engineering")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2012 - 2014 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Developed individual components for larger industrial process control systems and worked with electronic engineers to build automated solutions for large WA enterprises"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Helped the engineering team to adopt new development tools in the industry based around Arduino and Raspberry Pi rapid-prototyping trends"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Artech" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-5")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Full-Stack Web Developer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Artech Web")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2010 - 2012 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Engineered and maintained major websites and backend systems using ES6, Handlebars, Backbone, Python Django, and CSS/Sass"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Proposed and implemented scalable solutions to issues identified with cloud services and applications responsible for communicating with numerous enterprise API’s"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Collaborates with UX designers and other developers to ensure thoughtful and coherent user experiences across web and related iOS/Android mobile apps"
                          )
                        ])
                      ]
                    )
                  ]
                ),
                _c(
                  "b-tab",
                  {
                    attrs: { title: "Haycom" },
                    on: {
                      click: function($event) {
                        return _vm.setListTracker("item-6")
                      }
                    }
                  },
                  [
                    _c("h5", { staticClass: "hover-under" }, [
                      _c("span", [_vm._v("Full-Stack Web Developer")]),
                      _c("span", { staticClass: "text-email" }, [_vm._v("@")]),
                      _c(
                        "a",
                        {
                          staticClass: "text-teal",
                          attrs: {
                            href: "#",
                            rel: "nofollow noopener noreferrer"
                          }
                        },
                        [_vm._v("Haycom Technlogies")]
                      )
                    ]),
                    _c("h6", { staticClass: "text-light" }, [
                      _vm._v(" 2005 - 2009 ")
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass:
                          "section-list list-unstyled list-feature text-lighter mb-0 b-0 w-100"
                      },
                      [
                        _c("li", [
                          _vm._v(
                            "Developed and maintained Microsoft servers and systems for in-house and client websites primarily using HTML, CSS, Sass, JavaScript, and jQuery"
                          )
                        ]),
                        _c("li", [
                          _vm._v(
                            "Planned, built and deployed over a hundred Microsoft Server 2003 backend and server platforms for SME's in Perth"
                          )
                        ])
                      ]
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }