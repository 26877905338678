<template>
  <div
    id="wrapper-navbar"
    itemscope
    itemtype="http://schema.org/WebSite"
    :class="{ toggled: toggled }"
  >
    <a
      class="skip-link sr-only sr-only-focusable"
      href="#content"
    >Skip to content</a>
    <b-navbar
      toggleable="lg"
      type="dark"
    >
      <b-navbar-brand @click="scrollElementIntoView('app')">
        <router-link to="/">
          <img
            src="@/assets/logo-stroke.svg"
            width="54"
            height="54"
            alt=""
          >
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <span
            v-if="expanded"
            class="toggler-icon-close"
          />
          <span
            v-else
            class="toggler-icon"
          />
        </template>
      </b-navbar-toggle>
      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="scrollElementIntoView('about')">
            <b-button variant="link-light">
              About
            </b-button>
          </b-nav-item>
          <b-nav-item @click="scrollElementIntoView('experience')">
            <b-button variant="link-light">
              Experience
            </b-button>
          </b-nav-item>
          <b-nav-item @click="scrollElementIntoView('work')">
            <b-button variant="link-light">
              Work
            </b-button>
          </b-nav-item>
          <b-nav-item @click="scrollElementIntoView('blog')">
            <b-button variant="link-light">
              Projects
            </b-button>
          </b-nav-item>
          <b-nav-item @click="scrollElementIntoView('contact')">
            <b-button variant="link-light">
              Contact
            </b-button>
          </b-nav-item>
          <b-nav-item
            href="https://s3.ap-southeast-2.amazonaws.com/ghostpirates/static/TYSON-BROUN-RESUME.pdf"
            target="_blank"
          >
            <b-button variant="outline-primary">
              Resume
            </b-button>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'NavMenu',
  data: function() {
    return {
      toggled: false
    };
  },
  methods: {
    scrollElementIntoView: function(elem, event) {
      if (event) {
        event.preventDefault();
      }
      if (this.$route.name !== 'Home') {
        console.log('pushing to Home');
        this.$router.push({ path: '/', name: 'Home' });
        setTimeout(() => {
          if (elem && document.getElementById(elem)) {
            // console.log({ 'scrollIntoViewElem': elem, 'scrollEvent': event });
            document.getElementById(elem).scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start'
            });
          }
        }, 2000);
      }
      if (this.$route.name === 'Home') {
        if (elem && document.getElementById(elem)) {
          // console.log({ 'scrollIntoViewElem': elem, 'scrollEvent': event });
          document.getElementById(elem).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
          });
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.btn {
  &.btn-link-light {
    &:hover {
      box-shadow: 0 0 0 1px rgb(42 161 152 / 7%);
      border-color: rgba(42,161,152,.07);
      background: rgba(38,139,210,.0125) !important;
    }
  }
}
</style>
