<template>
  <section
    id="work"
    name="work"
    class="section fp-section fp-section-four"
  >
    <h3
      class="mb-5 heading-count text-lighter animate__animated animate__fadeInUp"
      style="transition-delay: 100ms;"
    >
      Some Things I&rsquo;ve Built
    </h3>
    <template
      v-for="(post,index) in featuredPosts"
      class="mb-5"
    >
      <div
        v-if="index % 2 === 0"
        :key="post.uuid"
        class="row w-100 pt-3 pb-5"
      >
        <div class="col col-lg-26">
          <div
            v-if="post.webmurl !== 'FALSE' && post.mp4url !== 'FALSE'"
            class="project-img-wrapper"
          >
            <a
              class="project-img-link"
              :href="post.link"
              target="_blank"
              @mouseover="play"
              @mouseleave="pause"
            >
              <div class="project-img-filter embed-container">
                <video
                  id="inlineVideo"
                  ref="videoElem"
                  width="450"
                  height="600"
                  loop
                  playsinline
                  :poster="post.img"
                  muted
                  preload
                  class="project-img project-video"
                >
                  <source
                    src="https://cdn.monkeyisland.com.au/static/hibot/hibot.webm"
                    type="video/webm"
                  >
                  <source
                    src="https://cdn.monkeyisland.com.au/static/hibot/hibot.mp4"
                    type="video/mp4"
                  >
                  <p>Your browser does not support the video tag.</p>
                </video>
              </div>
            </a>
          </div>
          <div
            v-else
            class="project-img-wrapper"
          >
            <a
              class="project-img-link"
              :href="post.link"
              target="_blank"
            >
              <div class="project-img-filter">
                <img
                  class="project-img"
                  sizes="(max-width: 700px) 100vw, 700px"
                  :src="post.img"
                  :alt="post.title"
                  :width="post.imgH"
                  :height="post.imgH"
                  loading="lazy"
                >
              </div>
            </a>
          </div>
        </div>
        <div class="col col-lg-22 d-flex flex-column align-items-end justify-content-center">
          <h4 class="text-teal h6 pt-3">
            Featured Project
          </h4>
          <h5 class="h3 mb-4">
            <a
              :href="post.link"
              class="text-lighter"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="External Link"
            >{{ post.title }}</a>
          </h5>
          <div
            class="callout-block"
            v-html="markdown(post.desc)"
          />
          <div class="d-block py-3 px-0">
            <ul class="list-unstyled list-horizontal">
              <li
                v-for="(tag,tagIndex) in renderTags(post.tags)"
                :key="tagIndex"
              >
                {{ tag }}
              </li>
            </ul>
          </div>
          <div class="d-block pb-3 px-0">
            <ul class="list-unstyled list-horizontal">
              <li>
                <a
                  :href="post.git"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label="GitHub Link"
                >
                  <iconGithub />
                </a>
              </li>
              <li>
                <a
                  :href="post.git"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label="Gitlab Link"
                >
                  <iconGitlab />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        v-if="index % 2 === 1"
        :key="post.uuid"
        class="row row-alt w-100 py-5"
      >
        <div class="col col-lg-22 d-flex flex-column align-items-start justify-content-center">
          <h4 class="text-teal h6 pt-3">
            Featured Project
          </h4>
          <h5 class="h3 mb-4">
            <a
              :href="post.link"
              class="text-lighter"
              target="_blank"
              rel="nofollow noopener noreferrer"
              aria-label="External Link"
            >{{ post.title }}</a>
          </h5>
          <div
            class="callout-block"
            v-html="markdown(post.desc)"
          />
          <div class="d-block py-3 px-0">
            <ul class="list-unstyled list-horizontal">
              <li
                v-for="(tag,tagIndex) in renderTags(post.tags)"
                :key="tagIndex"
              >
                {{ tag }}
              </li>
            </ul>
          </div>
          <div class="d-block pb-3 px-0">
            <ul class="list-unstyled list-horizontal">
              <li>
                <a
                  :href="post.git"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label="GitHub Link"
                >
                  <iconGithub />
                </a>
              </li>
              <li>
                <a
                  :href="post.git"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label="Gitlab Link"
                >
                  <iconGitlab />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col col-lg-26">
          <div
            v-if="post.webmurl !== 'FALSE' && post.mp4url !== 'FALSE'"
            class="project-img-wrapper"
          >
            <a
              class="project-img-link"
              :href="post.link"
              target="_blank"
              @mouseover="play"
              @mouseleave="pause"
            >
              <div class="project-img-filter embed-container">
                <video
                  id="inlineVideo"
                  ref="videoElem"
                  width="450"
                  height="600"
                  loop
                  playsinline
                  :poster="post.img"
                  muted
                  preload
                  class="project-img project-video"
                >
                  <source
                    src="https://cdn.monkeyisland.com.au/static/hibot/hibot.webm"
                    type="video/webm"
                  >
                  <source
                    src="https://cdn.monkeyisland.com.au/static/hibot/hibot.mp4"
                    type="video/mp4"
                  >
                  <p>Your browser does not support the video tag.</p>
                </video>
              </div>
            </a>
          </div>
          <div
            v-else
            class="project-img-wrapper"
          >
            <a
              class="project-img-link"
              :href="post.link"
              target="_blank"
            >
              <div class="project-img-filter">
                <img
                  class="project-img"
                  sizes="(max-width: 700px) 100vw, 700px"
                  :src="post.img"
                  :alt="post.title"
                  :width="post.imgH"
                  :height="post.imgH"
                  loading="lazy"
                >
              </div>
            </a>
          </div>
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import iconGithub from '@/assets/icon-github';
import iconGitlab from '@/assets/icon-gitlab';
import _ from 'lodash';
const md = require('markdown-it')();

export default {
  name: 'S4Folio',
  components: {
    iconGithub,
    iconGitlab
  },
  computed: {
    posts() {
      return this.$root.posts;
    },
    loading() {
      return this.$root.loading;
    },
    featuredPosts() {
      return _.filter(this.$root.posts, { status: 'featured' });
    }
  },
  methods: {
    markdown: function(value) {
      if (!value) {
        return '';
      }
      value = value.toString();
      return md.render(value);
    },
    renderTags: function(value) {
      if (value !== null && value !== undefined && value.length > 0) {
        return value.split('|');
      }
      return '';
    },
    play: function() {
      console.log('mouseover', this);
      if (this.$refs.videoElem[0].paused) {
        this.$refs.videoElem[0].play();
      }
    },
    pause: function() {
      console.log('mouseleave', this);
      this.$refs.videoElem[0].pause();
    }
  }
};

</script>

<style lang="scss">

</style>
